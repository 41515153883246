import React, { useEffect } from 'react'

const MapPage = () => {
  useEffect(() => {
    // Redirect to the static HTML file
    window.location.href = '/map.html'
  }, [])

  return <div>Loading...</div>
}

export default MapPage
